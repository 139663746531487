import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"

export default props => (
  <Link
    {...props}
    sx={{
      color: "inherit",
      textDecoration: "none",
      "&.active": {
        color: "primary",
      },
    }}
  />
)
