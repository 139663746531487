import React from "react"
import { Flex } from "theme-ui"
import Link from "./link"

const Footer = () => (
    <Flex as="footer" sx={{
        justifyContent: "center",
        py: 4
    }}>
        {/* <Link to="/">Lanka Capital Ltd.{" "}</Link> © {new Date().getFullYear()} */}
        Copyright © {new Date().getFullYear()}&nbsp;<Link to="/">Lanka Capital Ltd.</Link>
        {/* ·
        Made by
        {` `}
        <a href="https://dseoane.com">Daniel Seoane</a> */}
    </Flex>
)

export default Footer