import PropTypes from "prop-types"
import React from "react"
import { Box, Flex, Text } from "theme-ui"
import Link from "./link"

const Header = ({ siteTitle }) => (
  <Flex
    as="header"
    mx={4}
    sx={{
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: 1100,
      px: 3,
      py: [3, null, null, 4],
    }}
  >
    <Flex
      sx={{
        alignItems: "center",
      }}
    >
      <Box
        mr={4}
        as={Link}
        to="/"
        sx={{
          fontFamily: "Merriweather",
          fontWeight: "bold",
          fontSize: 4,
        }}
      >
        Lanka Capital
      </Box>
      <Box>Investment Projects</Box>
    </Flex>
    <Box sx={{ flexGrow: 1 }} />
    <Box sx={{ display: ["none", null, null, "flex"] }}>
      <Flex
        sx={{
          alignItems: "center",
          px: 4,
        }}
      >
        <Text
          as={Link}
          to="#our-proposal"
          variant="caps"
          sx={{
            py: 2,
            fontWeight: 600,
            borderBottom: "2px solid transparent",
            "&&:hover": {
              borderBottom: "2px solid black",
            },
          }}
        >
          Our proposal
        </Text>
      </Flex>
      <Flex
        sx={{
          alignItems: "center",
          px: 4,
        }}
      >
        <Text
          as={Link}
          to="#fields-of-activity"
          variant="caps"
          sx={{
            py: 2,
            fontWeight: 600,
            borderBottom: "2px solid transparent",
            "&&:hover": {
              borderBottom: "2px solid black",
            },
          }}
        >
          Fields of activity
        </Text>{" "}
      </Flex>
      <Flex
        sx={{
          alignItems: "center",
          px: 4,
        }}
      >
        <Text
          as={Link}
          to="#contact-us"
          variant="caps"
          sx={{
            py: 2,
            fontWeight: 600,
            borderBottom: "2px solid transparent",
            "&&:hover": {
              borderBottom: "2px solid black",
            },
          }}
        >
          Contact us
        </Text>
      </Flex>
    </Box>
  </Flex>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
